import { useEffect, useState } from "react";

const Users = () => {
  const url = "https://permatauk.com/api/";
  const [data, setData] = useState([]);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "load-users");

    fetch(url, {
      method: "POST",
      body: form,
    })
    .then((res) => res.json())
    .then((res) => {
      setData(res);
    })
    .catch((err) => console.log(err));

  }, []);

  const handelSelectAll = () => {
    let checkboxes = document.getElementsByClassName("checkbox");
    for (let i = 0; i < checkboxes.length; i++) {
      if(toggle){
        checkboxes[i].checked = false;
      } else {
        checkboxes[i].checked = true;
      }
    }
    setToggle(!toggle);
  }

  const handelDelete = () => {
    let checkboxes = document.getElementsByClassName("checkbox");

    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "delete-item");
    form.append("q", 'users');
    
    for (let i = 0; i < checkboxes.length; i++) {
      if(checkboxes[i].checked){
        form.append("item_id[]", checkboxes[i].value);
      }
    }
    
    let r = window.confirm("Are you sure to delete?");

    if(r){
      fetch(url, {
        method: "POST",
        body: form,
      })
      .then((res) => res.json())
      .then((res) => {
        window.location.reload();
      })
      //.catch((err) => console.log(err));
    }

  }

  return (<>
    <h2>Users List <a href="/new-user" className="btn"><i className="fa fa-plus"></i> New User</a></h2>

    <div className="inline-list col-3">
      <button className="inline-item" onClick={handelSelectAll}><i className="fa fa-list"></i> Select All</button>
      <button className="inline-item" onClick={handelDelete}><i className="fa fa-trash"></i> Delete</button>
    </div>
  
    <div className="list-view">
      {data && data.length > 0 && data.map((item, i) => (<label htmlFor={"checkbox"+item.id} className="list-item inline-list" key={"item"+item.id}>
        <span className="inline-item">
          <input type="checkbox" className="checkbox" name="checkbox[]" id={"checkbox"+item.id} value={item.id} />
        </span>
        <span className="inline-item col-2">{item.name}</span>
        <div className="inline-item tag"><i className="fa fa-tags"></i> {item.userType === 'admin'?'Admin':'User'}</div>
      </label>))}
    </div>
  </>);
}

export default Users;