import { useEffect, useState } from "react";

const Home = () => {
  const url = "https://permatauk.com/api/";
  const [data, setData] = useState([]);
  const [dataUnPaid, setDataUnPaid] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [total, setTotal] = useState(0);
  const [total_number, setTotalNumber] = useState(0);

  useEffect(() => {
    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "load-applications");
    form.append("q", window.localStorage.getItem("osys_user"));

    let form2 = new FormData();
    form2.append("key", window.localStorage.getItem("osys_token"));
    form2.append("section", "monthly-applications-total");

    let form3 = new FormData();
    form3.append("key", window.localStorage.getItem("osys_token"));
    form3.append("section", "load-applications-unpaid");


    fetch(url, {
      method: "POST",
      body: form,
    })
    .then((res) => res.json())
    .then((res) => {
      setData(res);
    })
    .catch((err) => console.log(err));

    fetch(url, {
      method: "POST",
      body: form2,
    })
    .then((res) => res.json())
    .then((res) => {
      setTotal(res[0].total_applications);
      setTotalNumber(res[0].total_number);
    })
    //.catch((err) => console.log(err));
    .then(() => {
      fetch(url, {
        method: "POST",
        body: form3,
      })
      .then((res) => res.json())
      .then((res) => {
        setDataUnPaid(res);
      })
      //.catch((err) => console.log(err));
    })

  }, []);

  const handelSearch = val => {
    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "search-applications");
    form.append("q", val.target.value);

    if(val.target.value.length > 3){
      fetch(url, {
        method: "POST",
        body: form,
      })
      .then((res) => res.json())
      .then((res) => {
        setSearchData(res);
      })
      //.catch((err) => console.log(err));
    }
  }

    return (
      <div className="inline-list">
        <h2>PERMATA</h2>

        <input type="text" placeholder="Search" className="col-3" onChange={handelSearch} />
        <div className="list-view">
          {searchData && searchData.length > 0 && searchData.map(item => (<a href={"/applications/"+item.id} className="list-item">{item.name}</a>))}
        </div>
        <hr />

        <div className="inline-item col-3 mrgn">
          <h3><i className="fa fa-list"></i> Applications</h3>
          <div className="card inline-list center">
            <div className="inline-item col-3">
              <p><i className="fa fa-calendar"></i> This Month</p>
              <h1>{total_number}</h1>
              <h4>Application</h4>
              
            </div>
          </div>
        </div>

        <div className="inline-item col-3 mrgn">
          <h3><i className="fa fa-clock"></i> Latest</h3>
          <div className="list-view">
            {data && data.length > 0 && data.map(item => (<a href={"/applications/"+item.id} className="list-item inline-list" key={"item"+item.id}>
              <span className="inline-item col-2">{item.name}</span>
              <div className="inline-item tag"><i className="fa fa-tags"></i> {item.status}</div>
            </a>))}
          </div>
        </div>

        
        <div className="inline-item col-3 mrgn">
          <h3><i className="fa fa-clock"></i> Unpaid</h3>
          <div className="list-view">
            {dataUnPaid && dataUnPaid.length > 0 && dataUnPaid.map(item => (<a href={"/applications/"+item.id} className="list-item inline-list" key={"item"+item.id}>
              <span>{item.name}</span>
              <br />
              <small>{item.reg_date}</small>
            </a>))}
          </div>
        </div>

      </div>
    );
}

export default Home;