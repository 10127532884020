import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Application from "./Application";
import Applications from "./Applications";
import Users from "./Users";
import User from "./User";
import Reports from "./Reports";
import Destinations from "./Destinations";
import Rooms from "./Rooms";
import Webdata from "./Webdata";
import Editcontent from "./Editcontent";
import Editdestination from "./Editdestination";
import Editroom from "./Editroom";
import Newdestination from "./Newdestination";
import Newroom from "./Newroom";


const App = () => {
  const url = "https://permatauk.com/api/";
  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("user");
  const [error, setError] = useState("");
  const [tokenkey, setToken] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "check-user");

    fetch(url, {
      method: "POST",
      body: form,
    })
    .then((res) => res.json())
    .then((res) => {
      setLoading(false)
      if(res.status === "success"){
        setLoggedIn(true)
      } else {
        setLoggedIn(false);
      }
    })
    .catch((err) => {
      setLoggedIn(false)
      setLoading(false)
    })
    
    setUserType(window.localStorage.getItem("osys_userType"));
  }, []);

  const handelLogin = () => {
    setError('');
    let form = new FormData();
    //form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "login");
    form.append("email", email);
    form.append("password", password);

    if(email === "" || password === ""){
      setError("Please enter your email and password");
    } else {
      fetch(url, {
        method: "POST",
        body: form,
      })
      .then((res) => res.json())
      .then((res) => {
        if(res.status === "success"){
          setLoggedIn(true);
          localStorage.setItem("osys_token", res.token);
          localStorage.setItem("osys_user", res.user);
          localStorage.setItem("osys_userType", res.usertType);
          window.location.reload();
        } else {
          setLoggedIn(false);
          setError('Wrong email or password');
        }
      })
      .catch((err) => {
        setLoggedIn(false);
        setError('Server Error. Please try again later');
      });
    }
  }

  const handelLogout = () => {
    setLoggedIn(false);
    localStorage.removeItem("osys_token");
    localStorage.removeItem("osys_user");
  }

  const toggleMenu = () => {
    document.querySelector("#menu").classList.toggle("mobile-menu");
  }

  return (<>
    {loading && <div className="loading">
        <img src={require('./logo.png')} />
    </div>}
    <div className="contanier">
      {!loggedIn && <div className="card col-2 mrgn inline-list center-div">
        <div className="inline-item col-3">
          <img src={require('./logo.png')} className="col-1" />
        </div>
        <div className="inline-item col-3">
          <h2>DASHBOARD LOGIN</h2>
          <label htmlFor="email">Username</label>
          <input type="text" id="email" className="col-1" onChange={val => setEmail(val.target.value)}/>
          <label htmlFor="password">Password</label>
          <input type="password" id="password" className="col-1" onChange={val => setPassword(val.target.value)} />
          <p className="error">{error}</p>
          <button onClick={handelLogin}>Login</button>
        </div>

      </div>}

      {loggedIn && <><nav>
        <div className="center">
          <img src={require('./logo.png')} className="logo" />
        </div>
        <button className="btn mobile-btn" onClick={toggleMenu}>
          <h1><i className="fa fa-bars"></i> Main Menu</h1>
        </button>
        <div id="menu" className="mobile-menu">
          <a href="/"><i className="fa fa-home"></i> Home</a>
          <a href="/applications"><i className="fa fa-list"></i> Applications</a>
          <a href="/destinations"><i className="fa fa-hotel"></i> Destinations</a>
          <a href="/rooms"><i className="fa fa-bed"></i> Bedrooms</a>
          <a href="/contents"><i className="fa fa-edit"></i> Contents</a>
          {userType === 'admin' && <><a href="/reports"><i className="fa fa-file"></i> Reports</a>
          <a href="/users"><i className="fa fa-users"></i> Users</a></>}
          <button onClick={handelLogout}><i className="fa fa-arrow-right-from-bracket"></i> Logout</button>
          {/* <div className="pl"></div> */}
        </div>
      </nav>

      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/applications/:oid" element={<Application />} />
          <Route path="/applications" element={<Applications />} />
          <Route path="/destinations" element={<Destinations />} />
          <Route path="/destinations/:item" element={<Editdestination />} />
          <Route path="/users" element={<Users />} />
          <Route path="/new-user" element={<User />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/destinations" element={<Destinations />} />
          <Route path="/destinations/new" element={<Newdestination />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/rooms/new" element={<Newroom />} />
          <Route path="/rooms/:item" element={<Editroom />} />
          <Route path="/contents" element={<Webdata />} />
          <Route path="/contents/:item" element={<Editcontent />} />
        </Routes> 
      </main></>}


    </div>
    <div className="pl"></div>
    <div className="footer">
      <p className="copyright">&copy; PERMATA, All right reserved.</p>
    </div>
  </>);
}

export default App;
