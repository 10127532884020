import { useState } from "react";

const Newroom = () => {
    const url = "https://permatauk.com/api/";
    const [name, setName] = useState('');
    const [amount, setAmount] = useState('');
    const [imageUrl, setImageUrl] = useState(url+'images/placeholder.png');
    const [imageFile, setImageFile] = useState(null);

    const handleSave = () => {
        let userItems = new FormData();
        userItems.append("key", window.localStorage.getItem("osys_token"));
        userItems.append("section", "insert-room");
        userItems.append("name", name);
        userItems.append("amount", amount);
        userItems.append('image', imageFile)

        fetch(url, {
            method: "POST",
            body: userItems,
        })
        .then((res) => res.json())
        .then((res) => {
            //console.log(res);
            window.location.href = "/rooms";
        })
        .catch((err) => console.log(err));
    }

    const handleUpload = e => {
        setImageUrl(window.URL.createObjectURL(e.target.files[0]));
        setImageFile(e.target.files[0]);
    }

    return(<>
        <h1>New Room</h1>
        <div className="card">
            <label className="col-3 inline-item" htmlFor="name">
                Room Name
                <input id="name" type="text" placeholder="Title" className="col-1" onChange={val => setName(val.target.value)}  value={name}/>
            </label>
            <label className="col-3 inline-item" htmlFor="amount">
                Room Price
                <input id="amount" type="text" placeholder="Amount in US dollar, numbers only." className="col-1" onChange={val => setAmount(val.target.value)}  value={amount}/>
            </label>
            <label className="col-3 inline-item" htmlFor="image">
                <img src={imageUrl} className="col-4" alt="Room Image" />
                <input type="file" id="image" className="hidden" onChange={handleUpload} />
            </label>
            <br />
            <button onClick={handleSave}>Add</button>
        </div>
    </>)
}

export default Newroom