import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Editroom = () => {
    const url = "https://permatauk.com/api/";
    const {item} = useParams();
    const [name, setName] = useState('');
    const [amount, setAmount] = useState(0);
    const [imageUrl, setImageUrl] = useState(url+'images/placeholder.png');
    const [imageFile, setImageFile] = useState(null);

    useEffect(() => {
        let form = new FormData();
        form.append("key", window.localStorage.getItem("osys_token"));
        form.append("section", "load-room");
        form.append("q", item);
    
        fetch(url, {
          method: "POST",
          body: form,
        })
        .then((res) => res.json())
        .then((res) => {
            console.log(res);
          setName(res[0].title);
          setAmount(res[0].amount);
          if(res[0].image !== ''){
            setImageUrl(url + 'images/' + res[0].image);
          }
        })
        .catch((err) => console.log(err));
    
    }, []);

    const handleSave = () => {
        let userItems = new FormData();
        userItems.append("key", window.localStorage.getItem("osys_token"));
        userItems.append("section", "update-room");
        userItems.append("name", name);
        userItems.append("amount", amount);
        userItems.append('image', imageFile);
        userItems.append("q", item);

        fetch(url, {
            method: "POST",
            body: userItems,
        })
        .then((res) => res.json())
        .then((res) => {
            window.location.href = "/rooms";
        })
        .catch((err) => console.log(err));
    }

    const handleUpload = e => {
        setImageUrl(window.URL.createObjectURL(e.target.files[0]));
        setImageFile(e.target.files[0]);
    }

    return(<>
        <h1>Edit Room</h1>
        <div className="card">
            <label className="col-3 inline-item" htmlFor="name">
                Room Name
                <input id="name" type="text" placeholder="Title" className="col-1" onChange={val => setName(val.target.value)}  value={name}/>
            </label>
            <label className="col-3 inline-item" htmlFor="name">
                Room Price
                <input id="name" type="text" placeholder="Amount in US dollar, numbers only." className="col-1" onChange={val => setAmount(val.target.value)}  value={amount}/>
            </label>
            <label className="col-3 inline-item" htmlFor="image">
                <img src={imageUrl} className="col-4" alt="Room Image" />
                <input type="file" id="image" className="hidden" onChange={handleUpload} />
            </label>
            <br />
            <button onClick={handleSave}>Update</button>
        </div>
    </>)
}

export default Editroom