import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Editcontent = () => {
    const url = "https://permatauk.com/api/";
    const {item} = useParams();
    const [name, setName] = useState('');
    const [content, setContent] = useState('');

    useEffect(() => {
        let form = new FormData();
        form.append("key", window.localStorage.getItem("osys_token"));
        form.append("section", "load-content");
        form.append("q", item);
    
        fetch(url, {
          method: "POST",
          body: form,
        })
        .then((res) => res.json())
        .then((res) => {
            console.log(res);
          setName(res[0].title);
          setContent(res[0].description);
        })
        .catch((err) => console.log(err));
    
    }, []);

    const handleSave = () => {
        let userItems = new FormData();
        userItems.append("key", window.localStorage.getItem("osys_token"));
        userItems.append("section", "update-content");
        userItems.append("name", name);
        userItems.append("content", window.document.getElementById("content").innerHTML);
        userItems.append("q", item);

        fetch(url, {
            method: "POST",
            body: userItems,
        })
        .then((res) => res.json())
        .then((res) => {
            window.location.href = "/contents";
        })
        .catch((err) => console.log(err));
    }

    const performAction = command => {
        window.document.execCommand(command, false, null);
        window.document.getElementById("content").focus();
      }

    return(<>
        <h1>Edit</h1>
        <div className="card">
            <label className="col-3 inline-item" htmlFor="name">
                Headline
                <input id="name" type="text" placeholder="Title" className="col-1" onChange={val => setName(val.target.value)}  value={name}/>
            </label>
            <div className="inline-list">
                <button className="btn inline-item" onClick={() => performAction('bold')}><b>B</b></button>
                <button className="btn inline-item" onClick={() => performAction('underline')}><u>U</u></button>
                <button className="btn inline-item" onClick={() => performAction('italic')}><i>i</i></button>
            </div>
            <label className="col-3 inline-item" htmlFor="content">
                Content
                <div id="content" className="textarea" dangerouslySetInnerHTML={{__html: content}} contentEditable={true} onChange={val => setContent(val)}></div>
            </label>
            <br />
            <button onClick={handleSave}>Update</button>
        </div>
    </>)
}

export default Editcontent