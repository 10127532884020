import { useEffect, useState } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const Reports = () => {
  const url = "https://permatauk.com/api/";
  const [data, setData] = useState([]);
  const [edata, setEData] = useState([]);
  const [order_payment, setPayment] = useState(0);
  const [order_payment_state, setPaymentState] = useState("In Progress");
  const [country, setCountry] = useState("");
  const [month, setMonth] = useState('01');
  const [year, setYear] = useState("2022");
  const [seller, setSeller] = useState('');
  const [agentType, setAgentType] = useState("sellers");

  const handleView = () => {
    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "load-reports");
    form.append("q", country);
    form.append("month", month);
    form.append("year", year);
    form.append("order_payment", order_payment);
    form.append("order_state", order_payment_state);

    fetch(url, {
      method: "POST",
      body: form,
    })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
      let temp_total = 0;
      let temp_e = [];
      res.forEach(element => {
        temp_total += parseFloat(element.total);
        temp_e.push(Object.values(element));
      });
      //setTotal(temp_total)
      setData(res);
      setEData(temp_e);
    })
    //.catch((err) => console.log(err));
  }

  const loadYears = () => {
    let years = [];
    let thisYear = new Date().getFullYear();
    for (let i = 2022; i <= thisYear; i++) {
      years.push(i);
    }
    return years;
  }

  const handleExport = () => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    // Desired file extesion
    const fileExtension = ".xlsx";
    let fileName = Date.now();
    //Create a new Work Sheet using the data stored in an Array of Arrays.
    const workSheet = XLSX.utils.aoa_to_sheet(edata);
    // Generate a Work Book containing the above sheet.
    const workBook = {
      Sheets: { data: workSheet, cols: [] },
      SheetNames: ["data"],
    };
    // Exporting the file with the desired name and extension.
    const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
    const fileData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(fileData, fileName + fileExtension);

  }

  
  /*const handelSearch = (val, searchType) => {
  
    if(val.target.value.length > 0){
      if(searchType === "sellers"){
        setSeller('');
      }else{
        setOrderAgent('');
      }
    }

    setAgentType(searchType);
    if (searchType === "sellers") {
      setSellerName(null)
      setSearchData([]);
    } else {
      setAgentName(null)
      setSearchData([]);
    }
    let form = new FormData();
    form.append("key", window.localStorage.getItem("osys_token"));
    form.append("section", "search-"+searchType);
    form.append("q", val.target.value);

    if(val.target.value.length > 2){
      fetch(url, {
        method: "POST",
        body: form,
      })
      .then((res) => res.json())
      .then((res) => {
        setSearchData(res);
      })
      //.catch((err) => console.log(err));
    }
  }

  const addAgent = (name, item) => {
    if (agentType === "sellers") {
      setSellerName(name);
      setSeller(item)
    } else {
      setAgentName(name);
      setOrderAgent(item)
    }
    setSearchData([]);
  }*/

  return (<>
    <h2>Reports</h2>
    <div className="inline-list">      
    <h1>
        <label className="inline-item" htmlFor="referrer">
            <select name="referrer" id="referrer" onChange={val => setMonth(val.target.value)}>
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="14">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
        </label>

        <label className="inline-item" htmlFor="referrer">
            <select name="referrer" id="referrer" onChange={val => setYear(val.target.value)}>
                {loadYears().map(item => (<option value={item}>{item}</option>))}
            </select>
        </label>

        <label className="inline-item" htmlFor="destination">
            <select name="destination" id="destination" onChange={val => setCountry(val.target.value)}>
                <option value="">All Countries</option>
                <option value="oman">Oman</option>
                <option value="uae">UAE</option>
                <option value="egypt">Egypt</option>
                <option value="lebanon">Lebanon</option>
            </select>
        </label>

        {/* <label className="inline-item" htmlFor="order_payment">
            <select name="order_payment" id="order_payment" value={order_payment} onChange={val => setPayment(val.target.value)}>
                <option value="">Payment</option>
                <option value={0}>Unpaid</option>
                <option value={1}>Paid</option>
            </select>
        </label> */}

        <label className="inline-item" htmlFor="order_state">
            <select name="order_state" id="order_state" value={order_payment_state} onChange={val => setPaymentState(val.target.value)}>
                <option value="">Status</option>
                <option value="In Progress">In Progress</option>
                <option value="Review">Review</option>
                <option value="Done">Done</option>
            </select>
        </label>

        {/* <label className="inline-item" htmlFor="order_agent">
            <input type="text" name="order_agent" id="order_agent" placeholder="Staff" value={seller_name} onChange={val => handelSearch(val, 'staff')}/>
            {searchData && searchData.length > 0 && searchData.map(item => (<div onClick={() => addAgent(item.name, item.id)} className="list-item"><i className="fa fa-user"></i> {item.name}</div>))}
        </label> */}
        <br />
        
    </h1>
        <h2 className="mrgn">
          <button className="inline-item mrgn" onClick={handleView}><i className="fa fa-eye"></i> Show</button>
          <button className="inline-item mrgn" onClick={handleExport}><i className="fa fa-download"></i> Export</button>
        </h2>
    </div>

    {/* {total > 0 && <div className="inline-item card col-2">Total: {total}</div>} */}

    <div className="list-view">
      {data && data.length > 0 && data.map((item, i) => (<a className="list-item inline-list" href={"/applications/"+item.id} key={"item"+item.id}>
        <span className="inline-item col-2">{item.name}</span>
        <div className="inline-item tag"><i className="fa fa-tags"></i> {item.order_state?'Paid':'Unpaid'}</div>
        <div className="inline-item tag"><i className="fa fa-tags"></i> {item.status}</div>
      </a>))}
    </div>
  </>);
}

export default Reports;