import { useState } from "react";

const Newdestination = () => {
    const url = "https://permatauk.com/api/";
    const [name, setName] = useState('');
    const [infoUrl, setInfoUrl] = useState('');
    const [price, setPrice] = useState('');
    const [offer, setOffer] = useState('0');
    const [imageUrl, setImageUrl] = useState(url+'images/placeholder.png');
    const [imageFile, setImageFile] = useState(null);

    const handleSave = () => {
        let userItems = new FormData();
        userItems.append("key", window.localStorage.getItem("osys_token"));
        userItems.append("section", "insert-destination");
        userItems.append("name", name);
        userItems.append("url", infoUrl);
        userItems.append("offer", offer);
        userItems.append("amount", price);
        userItems.append("content", window.document.getElementById("content").innerHTML);
        userItems.append('image', imageFile);

        fetch(url, {
            method: "POST",
            body: userItems,
        })
        .then((res) => res.json())
        .then((res) => {
            //console.log(res);
            window.location.href = "/destinations";
        })
        .catch((err) => console.log(err));
    }

    const performAction = command => {
        window.document.execCommand(command, false, null);
        window.document.getElementById("content").focus();
    }

    const handleUpload = e => {
        setImageUrl(window.URL.createObjectURL(e.target.files[0]));
        setImageFile(e.target.files[0]);
    }

    return(<>
        <h1>New Destination</h1>
        <div className="card">
            <label className="col-3 inline-item" htmlFor="name">
                Title
                <input id="name" type="text" placeholder="Title" className="col-1" onChange={val => setName(val.target.value)}  value={name}/>
            </label>
            <label className="col-3 inline-item" htmlFor="url">
                More Information Internet Link
                <input id="url" type="text" placeholder="More Information Url" className="col-1" onChange={val => setInfoUrl(val.target.value)}  value={infoUrl}/>
            </label>
            <label className="col-3 inline-item" htmlFor="price">
                Price
                <input id="price" type="text" placeholder="Amount in US dollar, numbers only." className="col-1" onChange={val => setPrice(val.target.value)}  value={price}/>
            </label>
            <label className="col-3 inline-item" htmlFor="offer">
                <input id="offer" type="checkbox" onChange={val => setOffer(val.target.value)} checked={offer === '1'} value={1} /> Marked as an special offer price?
            </label>
            <label className="col-3 inline-item" htmlFor="content">
                <p>Content</p>
                <div className="inline-list">
                    <button className="btn inline-item" onClick={() => performAction('bold')}><b>B</b></button>
                    <button className="btn inline-item" onClick={() => performAction('underline')}><u>U</u></button>
                    <button className="btn inline-item" onClick={() => performAction('italic')}><i>i</i></button>
                </div>
                <div id="content" className="textarea" contentEditable={true}></div>
            </label>
            <label className="col-3 inline-item" htmlFor="image">
                <img src={imageUrl} className="col-4" alt="Destination Image" />
                <input type="file" id="image" className="hidden" onChange={handleUpload} />
            </label>
            <br />
            <button onClick={handleSave}>Add</button>
        </div>
    </>)
}

export default Newdestination;