import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const Application = () => {
    const url = "https://permatauk.com/api/";
    const { oid } = useParams();
    const [data, setData] = useState([]);
    const [order_state, setStatus] = useState("In Progress");
    const [order_payment, setPayment] = useState(0);
    const [notesList, setNotes] = useState([]);
    const [updatesList, setUpdates] = useState([]);
    const [noteText, setNoteText] = useState("");
  
    useEffect(() => {
        let form = new FormData();
        form.append("key", window.localStorage.getItem("osys_token"));
        form.append("section", "load-application");
        form.append("q", oid);

        let loadNotes = new FormData();
        loadNotes.append("key", window.localStorage.getItem("osys_token"));
        loadNotes.append("section", "load-notes");
        loadNotes.append("q", oid);
  
        let loadUpdates = new FormData();
        loadUpdates.append("key", window.localStorage.getItem("osys_token"));
        loadUpdates.append("section", "load-application-updates");
        loadUpdates.append("q", oid);

        fetch(url, {
            method: "POST",
            body: form,
        })
        .then((res) => res.json())
        .then((res) => {
            setData(res);
            setStatus(res[0].status);
            setPayment(res[0].paid);
        })
        //.catch((err) => console.log(err))

        .then(() => {
            fetch(url, {
                method: "POST",
                body: loadNotes,
            })
            .then((jres) => jres.json())
            .then(resData => {
                setNotes(resData);
            })
            //.catch((notes_err) => console.log(notes_err))
            .then(() => {
                fetch(url, {
                    method: "POST",
                    body: loadUpdates,
                })
                .then((jres) => jres.json())
                .then(uresData => {
                    setUpdates(uresData);
                })
                //.catch((notes_err) => console.log(notes_err))

            })
            
        })
  
    }, []);

    const handleUpdate = () => {
        let form = new FormData();
        form.append("key", window.localStorage.getItem("osys_token"));
        form.append("section", "update-application");
        form.append("q", oid);
        form.append("order_state", order_state);
        form.append("order_payment", order_payment);
        form.append("user_id", window.localStorage.getItem("osys_user"));

        let updateForm = new FormData();
        updateForm.append("key", window.localStorage.getItem("osys_token"));
        updateForm.append("section", "insert-application-update");
        updateForm.append("q", oid);
        updateForm.append("user_id", window.localStorage.getItem("osys_user"));
        
        fetch(url, {
            method: "POST",
            body: form,
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.status === "success") {
                fetch(url, {
                    method: "POST",
                    body: updateForm,
                })
                .then((res) => res.json())
                .then((res) => {
                    window.location.reload();
                })
                //.catch((err) => console.log(err));
            } else {
                alert("Sorry, an error occured");
            }
        }
        )
        .catch((err) => console.log(err));

    }

    const handleAddNote = () => {
        let form = new FormData();
        form.append("key", window.localStorage.getItem("osys_token"));
        form.append("section", "insert-note");
        form.append("note_user", window.localStorage.getItem("osys_user"));
        form.append("q", oid);
        form.append("note", noteText);

        fetch(url, {
            method: "POST",
            body: form,
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.status === "success") {
                window.location.reload();
            }
        })
        //.catch((err) => console.log(err));
    }

    const handleDelete = () => {
        let form = new FormData();
        form.append("key", window.localStorage.getItem("osys_token"));
        form.append("section", "delete-applications");
        form.append("q", oid);

        let r = window.confirm("Are you sure to delete?");

        if(r){
            fetch(url, {
                method: "POST",
                body: form,
            })
            .then((res) => res.json())
            .then((res) => {
                if (res.status === "success") {
                    window.location.href = '/applications';
                }
            })
            //.catch((err) => console.log(err));
        }
    }

    const handlePrint = () => {
        let frm = document.getElementById('ipd').contentWindow;
        frm.focus();
        frm.print();
    }

    useEffect(() => {
        let mywindow = document.getElementById('ipd').contentWindow.document;
        
        if(data.length > 0){
            mywindow.open();
            mywindow.write('<html><head><title>'+data[0].token+'</title><meta http-equiv="X-UA-Compatible" content="IE=EmulateIE11"/><meta name="viewport" content="width=device-width, initial-scale=1" /><style>@media print { body {background-image: url('+url+'images/letterhead.jpg) 100% 100%} .footer,#non-printable {display: none !important;} #printable {display: block;} .inline-list .inline-item {display: inline-block; padding: 5px; margin: 2px; width: 45%;}}</style></head><body tyle="color: #000; padding: 5px">')
            
            mywindow.write('<img src="'+url+'images/letterhead.jpg" style="position: fixed; z-index: -1; width: 100%" />')
            mywindow.write('<div style="padding: 12%; margin: 3px"></div>')
            mywindow.write(''+data[0].reg_date)
            mywindow.write('<h3>Student Name: '+data[0].name+'</h3>')
            mywindow.write('<div class="inline-list">')
            mywindow.write('<div class="inline-item">Gender: '+data[0].gender+'</div>')
            mywindow.write('<div class="inline-item">Birthdate: '+data[0].birthdate+'</div><br/>')
            mywindow.write('<div class="inline-item">Mobile: '+data[0].mobile+'</div>')
            mywindow.write('<div class="inline-item">E-mail: '+data[0].email+'</div><br/>')
            mywindow.write('<div class="inline-item">Country: '+data[0].country+'</div>')
            mywindow.write('<div class="inline-item">Province: '+data[0].province+'</div><br/>')
            mywindow.write('<div class="inline-item">City: '+data[0].state_name+'</div>')
            mywindow.write('<div class="inline-item">P.O.: '+data[0].po+' - Postal Code: '+data[0].postal+'</div><br/>')
            mywindow.write('<div class="inline-item">Passport No: '+data[0].passport+'</div>')
            mywindow.write('<div class="inline-item">Passport Expire Date: '+data[0].passexpire+'</div><br/>')
            mywindow.write('<p>Disability/Illness: <br/>'+data[0].disability+'</p><hr/>')
            mywindow.write('<p><b>'+data[0].study+'</b></p>')
            mywindow.write('<div class="inline-item">Destination: '+data[0].destination+'</div>')
            mywindow.write('<div class="inline-item">City: '+data[0].city+'</div><br/>')
            mywindow.write('<div class="inline-item">Start Date: '+data[0].start+'</div>')
            mywindow.write('<div class="inline-item">End Date: '+data[0].end+'</div><br/>')
            mywindow.write('<div class="inline-item">Arrival Date: '+data[0].arrival+'</div>')
            mywindow.write('<div class="inline-item">Departure Date: '+data[0].departure+'</div><br/>')
            mywindow.write('<div class="inline-item">Room Type: '+data[0].ticket+'</div>')
            mywindow.write('<div class="inline-item">Status: '+data[0].status+'</div><br>') 
            mywindow.write('<p>Specail Request: <br/> '+data[0].sprequist+'</p><hr/>')
            mywindow.write('<div style="text-align: center"><img src="'+url+'images/'+data[0].photo+'" style="display: block; width: 25%" /></div>')           
            mywindow.write('</div>')

            mywindow.close()
        }

    }, [data])

    return (<>
        {data && data.map(item => (<div key={item.token}><h1><i className="fa fa-file"></i>  {item.token}</h1>
        <div className="card inline-list">
            <div id="info">
                <h3>{item.name}</h3>
                <img src={url+"images/"+item.photo} className="col-4" alt=""/>
                <br />
                <p className="inline-item col-4">Gender: {item.gender}</p>
                <p className="inline-item col-4">Birthdate: {item.birthdate}</p>
                <br />

                <p className="inline-item col-4">Mobile: <a href={"tel:"+item.mobile}>{item.mobile}</a></p>
                <p className="inline-item col-4">email: <a href={"mailto:"+item.email}>{item.email}</a></p>

                <br />

                <p className="inline-item col-3">Passport No: {item.passport}</p>
                <p className="inline-item col-3">Passport Expire: {item.passexpire}</p>

                <br />


                <p className="inline-item col-3">Country: {item.country}</p>
                <p className="inline-item col-3">Province: {item.province}</p>

                <br />
                <p className="inline-item col-3">City: {item.state_name}</p>
                <p className="inline-item col-3">P.O.: {item.po} - Postal Code: {item.postal}</p>

                <br />
                <p className="inline-item col-3">Disability/Illness: {item.disability}</p>

                <hr />

                <p className="inline-item">Room Type: {item.ticket === '1'?'Single':item.ticket === '2'?'Double':item.ticket === '3'?'Triple':'Quad Bedroom'}</p>
                <br />
                <p></p>
                <p className="inline-item col-4">Destination: {item.country}</p>
                <p className="inline-item col-4">Study: {item.study}</p>
                <p className="inline-item col-4">City: {item.city}</p>
                <br />
                <p className="inline-item col-4">Start At: {item.start}</p>
                <p className="inline-item col-4">End At: {item.end}</p>
                
                <br />
                <p className="inline-item col-4">Arrival At: {item.arrival}</p>
                <p className="inline-item col-4">Departure At: {item.departure}</p>
                
                <br />
                <p>Specail Request: <br /> {item.sprequist}</p>
            </div>
            <div className="inline-list">
                <button className="inline-item mrgn" onClick={handlePrint}><i className="fa fa-print"></i> Print</button>
                <button className="inline-item mrgn" onClick={handleDelete}><i className="fa fa-trash"></i> Delete</button>

            </div>
        </div>
        

        <h2><i className="fa fa-user"></i> Application</h2>

        <div className="card inline-list">
            <div id="info2">
                <label className="col-3 inline-item" htmlFor="order_state">
                    status
                    <select name="order_state" id="order_state" value={order_state} onChange={val => setStatus(val.target.value)}>
                        <option value="In Progress">In Progress</option>
                        <option value="Review">Review</option>
                        <option value="Done">Done</option>
                    </select>
                </label>
                
                <label className="col-3 inline-item" htmlFor="order_payment">
                    Payment  
                    <select name="order_payment" id="order_payment" value={order_payment} onChange={val => setPayment(val.target.value)}>
                        <option value={1}>Paid</option>
                        <option value={0}>Unpaid</option>
                    </select>
                </label>

            </div>
            <button onClick={handleUpdate}><i className="fa fa-save"></i> Update</button>
        </div>

        </div>))}

        <h2><i className="fa fa-edit"></i> Notes</h2>

        <div className="card list-view2">
            <label htmlFor="note">
                New Note
                <textarea name="note" id="note" cols="50" rows="5" onChange={val => setNoteText(val.target.value)}></textarea>
            </label>

            <button onClick={handleAddNote}><i className="fa fa-plus"></i> Add</button>

            <hr />

            {notesList && notesList.length > 0 && notesList.map(note_item => (<div className="list-item" key={note_item.id}>
                <p>{note_item.note}</p>
                <small>{note_item.note_date} - By {note_item.user_name}</small>
                <hr />
            </div>))}
        </div>

        <h2><i className="fa fa-clock"></i> Update Records</h2>

        <div className="list-view">
            {data && data.length > 0 && <p className="list-item">Created at {data[0].reg_date}</p>}
            {updatesList && updatesList.length > 0 && updatesList.map(update_item => (<p className="list-item" key={update_item.id}>Updated At {update_item.update_date} By {update_item.user_name}</p>))}
        </div>
        <iframe id="ipd" style={{width: '1px', height: '1px'}}></iframe>
    </>)
}

export default Application;